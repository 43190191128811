<template>
  <table class="mainTable">
    <colgroup>
      <col width="15%">
      <col width="28.33%">
      <template v-for="item in myReport.reports" :key="item.category1">
        <col width="28.33%">
      </template>
      <col width="28.33%">
    </colgroup>

    <thead v-if="table === 'main'">
    <tr>
      <th>{{ $t('table.head.memName') }}</th>
      <th>{{ $t('table.head.holdingIs') }}</th>
      <!--        <th>{{ $t('table.head.casino') }} (1.0%)</th>-->
      <template v-for="item in myReport.reports" :key="item.category1">
        <th>{{ $t('table.head.casino') }}({{ $t('table.head.insurcover') }})<!--{{item.category1}}--></th>
      </template>
      <th>{{ $t('common.totals') }}({{ $t('common.totalsBi') }})</th>
    </tr>
    </thead>

    <tbody>
    <template v-if="list.length > 0">
      <insure-tr v-for="item in list" :data="item" :table="table" :reqData="reqData" :key="item.siteId" :myReport="myReport" />
    </template>
    </tbody>
  </table>

</template>

<script>
import InsureTr from '@/components/ui/insure/insureTr.vue'
import { mapState } from 'vuex'

export default {
  name: 'insureTable',
  components: {
    InsureTr
  },
  props: [
    'table',
    'headInfo',
    'list',
    'reqData',
    'myReport'
  ],
  computed: {
    ...mapState([
    ])
  }
}
</script>

<style scoped>
</style>
