<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" v-model:endDefault="endDefault" :withToday="withToday"/>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.searchId" :placeholder="'ID'" @keyup.enter="setTableData"/>
             <button class="btn-search" type="button" @click="setTableData">
                <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="total-wrap">
      <div class="yw">
       <h3 class="tableName">{{ $t('searchArea.myinTotal') }}</h3>
       <div class="totalBox">
         <table class="mainTable">
           <colgroup>
             <col width="50%">
             <col width="50%">
           </colgroup>
           <tbody v-if="myReport && myReport.reports">
             <tr>
             <template v-for="item in myReport.reports" :key="item.category1" :mybiCal="myReport.reports.biWinLose + myReport.reports.insuredAmt">
               <td>
                 <ul class="money">
                   <li class="head"><span>{{ $t('table.head.casino') }}<!--{{item.category1}}--></span>({{ $t('table.head.insurbets') }})</li>
                   <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(item.biBetAmt) }}</span></li>
                   <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(item.biWinAmt) }}</span></li>
                   <li class="border-bot"><em>{{ $t('table.head.wl') }}</em><span>{{ numberWithCommas(item.biWinLose) }}</span></li>
                   <!--li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(item.insuredPointAmt) }}</span></li-->
                   <li></li>
                   <li><em>{{ $t('table.head.insureMyshare') }}</em><span>{{ numberWithCommas(item.creditAmt*-1) }}</span></li>
                   <li></li>
                 </ul>
               </td>
             </template>

             <td>
               <ul class="money">
                 <li class="head"><span>{{ $t('common.totalsBi') }}</span></li>
                 <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(myReport.reportSum.biBetAmtSum) }}</span></li>
                 <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(myReport.reportSum.biWinAmtSum) }}</span></li>
                 <li class="border-bot"><em>{{ $t('common.rolling') }}[{{ $t('table.head.BIAmt') }}]</em><span>{{ numberWithCommas(myReport.reportSum.insuredPointAmtSum) }}</span></li>
                 <li class="border-bot"><em>{{ $t('table.head.wl') }}</em><span>{{ numberWithCommas(myReport.reportSum.biWinLoseSum) }}</span></li>
                 <li class="last"><em>{{ $t('table.head.insureM') }}</em><span>{{ numberWithCommas(myReport.reportSum.insuredAmtSum) }}</span></li>
                 <li><em>{{ $t('table.body.totalCalAmt') }}</em><span>{{ numberWithCommas(parseInt(myReport.reportSum.creditAmtSum*-1) + parseInt(myReport.reportSum.insuredPointAmtSum)) }}</span></li>
               </ul>
             </td>
           </tr>
           </tbody>
         </table>
       </div>
      </div>
      <div v-if="Object.keys(reportTotal).length > 0">
       <h3 class="tableName">{{ $t('searchArea.bTotal') }}</h3>
       <div class="totalBox">
         <table class="mainTable">
           <colgroup>
             <col width="33.33%">
             <col width="33.33%">
             <col width="33.33%">
           </colgroup>
           <tbody >
             <tr>
               <td>
                 <ul class="money">
                   <li class="head"><span>{{ $t('table.head.holdingIs') }}</span></li>
                   <li class="border-bot"><em>{{ $t('table.head.holdingIs') }}</em>{{ numberWithCommas(reportTotal.insuredBalance) }}</li>
                   <li class="border-bot"><em>{{ $t('table.body.pointAmt') }}</em>{{ numberWithCommas(reportTotal.insuredPointBalance) }}</li>
                   <li class="border-bot"></li>
                   <li class="border-bot"></li>
                   <li class="border-bot"></li>
                   <li></li>
                 </ul>
                </td>
               <template v-for="item in reportTotal.reports" :key="item.category1">
                 <td>
                   <ul class="money">
                     <li class="head"><span>{{ $t('table.head.casino') }}<!--{{item.category1}}-->({{ $t('table.head.insurM') }})</span></li>
                     <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(item.biBetAmt) }}</span></li>
                     <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(item.biWinAmt) }}</span></li>
                     <li class="border-bot"><em><!--{{ $t('common.rolling') }}--></em><span><!--{{ numberWithCommas(item.insuredPointAmt) }}--></span></li>
                     <li class="border-bot"><em><!--{{ $t('table.head.wl') }}--></em><span><!--{{ numberWithCommas(item.biWinLose) }}--></span></li>
                     <li><em><!--{{ $t('table.head.insureM') }}--></em><span><!--{{ numberWithCommas(item.insuredAmt) }}--></span></li>
                     <li></li>
                   </ul>
                 </td>
               </template>
                <td v-if="reportTotal.reportSum">
                  <ul class="money">
                    <li class="head"><span>{{ $t('common.totalsBi') }}</span></li>
                    <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredBetAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredWinAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('common.rolling') }}[{{ $t('table.head.BIAmt') }}]</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredPointAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('table.head.wl') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredAmtSum*-1) }}</span></li>
                    <li class="last"><em>{{ $t('table.head.insureM') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredAmtSum) }}</span></li>
                    <li><em>{{ $t('table.body.totalSettl') }}</em><span>{{ numberWithCommas(parseInt(reportTotal.reportSum.insuredAmtSum*-1) - parseInt(reportTotal.reportSum.insuredPointAmtSum)) }}</span></li>
                    <!--<li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.biBetAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.biWinAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredPointAmtSum) }}</span></li>
                    <li class="border-bot"><em>{{ $t('table.head.wl') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.biWinLoseSum) }}</span></li>
                    <li class="last"><em>{{ $t('table.head.insureM') }}</em><span>{{ numberWithCommas(reportTotal.reportSum.insuredAmtSum) }}</span></li>-->
                  </ul>
                </td>
              </tr>
           </tbody>
         </table>
       </div>
      </div>
    </div>
    <div class="main-contents-wrapper min-w1533" v-if="outSearchList">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ outSearchList ? outSearchList.length:0 }}</span>
          </div>
        </div>
      </article>
      <h3 class="tableName2">{{ $t('table.body.companySettl') }}</h3>
      <div class="table-wrapper">
        <insure-table v-if="outSearchList"
                      :table="'main'"
                      :headInfo="headInfo"
                      :list="outSearchList"
                      :myReport="myReport"
                      :reqData="reqData" :key="reqData.endDate"
                      :level="1" />
      </div>
    </div>
  </section>
</template>

<script>
import DateSelector from '@/components/common/DateSelector'
import { getDateStr } from '@/libs/utils.js'
import InsureTable from '@/components/ui/insure/insureTable.vue'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { insuredReport } from '@/api/insured'
import Memo from '@/components/common/memo'

export default {
  name: 'insureTotal',
  inheritAttrs: false,
  components: {
    InsureTable,
    DateSelector,
    Memo
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['memName', 'insureRate', 'betRate', 'holdingIs', 'casinoRate', 'totalBet']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        searchId: '',
        searchType: 'OUTER',
        chkTodayYn: 'Y',
        startDate: '',
        endDate: ''
      },
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      outSearchList: null,
      outSearchTotal: {},
      reportTotal: {},
      myReport: {},
      startDefault: '',
      endDefault: '',
      moreToggle: false,
      totalText: false,
      withToday: false
    }
  },
  computed: {
    ...mapState([
    ])
  },
  methods: {
    todayCheck () {
      let endDate = ''
      if (this.$refs.today.checked) {
        this.reqData.chkTodayYn = 'Y'
        this.withToday = true
        endDate = new Date()
      } else {
        this.reqData.chkTodayYn = 'N'
        endDate = addDays(new Date(), -1)
        this.withToday = false
      }
      this.setTableData()
    },
    setStartDate (date) {
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setTableData () {
      this.emitter.emit('Loading', true)
      const today = getDateStr(new Date(), 'yyyy-MM-dd')
      if (this.reqData.endDate === today) {
        this.reqData.chkTodayYn = 'Y'
      } else {
        this.reqData.chkTodayYn = 'N'
      }
      console.log('[req] : ', this.reqData)
      const res = await insuredReport(this.reqData)
      console.log('[res] : ', res)
      if (res.resultCode === '0') {
        this.outSearchList = res.data.reportList

        if (this.outSearchList.length > 0) {
          this.outSearchList.forEach(item => {
            if (item.reports.length > 0) {
              item.reports = item.reports.filter(subItem => {
                return subItem.category1 === 'casino'
              })
            }
          })
        }

        this.reportTotal = res.data.reportTotal
        if (this.reportTotal.reports && this.reportTotal.reports.length > 0) {
          this.reportTotal.reports = this.reportTotal.reports.filter(subItem => {
            return subItem.category1 === 'casino'
          })
        }

        this.myReport = res.data.myReport
        if (this.myReport.reports.length > 0) {
          this.myReport.reports = this.myReport.reports.filter(subItem => {
            return subItem.category1 === 'casino'
          })
        }

        if (res.data.pageInfo) {
          this.pageInfo = res.data.pageInfo
        }
      } else {
        this.outSearchList = null
        this.outSearchTotal = {}
        this.reportTotal = {}
        this.myReport = {}
      }

      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.blc {color: #0f84ee;font-weight: bold;}
.rdc {color: #eb7a3f;font-weight: bold;}
/* check */
.ui-check-wrap {
  font-size:1em;
  display: flex;
  justify-content: center;
  margin-right:10px;
}
.ui-check-wrap:last-child {
  margin-right:0;
}
.ui-check-wrap input{
  display:none;
}
.ui-check-wrap label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ui-check-wrap label:last-child {
  margin-right: 0;
}
.ui-check-wrap i {
  border: 2px solid #bfbfbf;
  background: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}
.ui-check-wrap input[type="checkbox"]:checked + label i {
  background: #18698b;
  border: 2px solid #353535;
}
.ui-check-wrap label > *:last-child {
  margin:0;
}
.total-wrap {display: flex;gap: 20px;}
.total-wrap > div {width: 100%;padding: 0 17px;}
.total-wrap > div:first-child .tableName {margin-right: 0;}
.total-wrap > div:last-child .tableName {margin-left: 0;}
.total-wrap > div:first-child .totalBox {margin-right: 0;}
.total-wrap > div:last-child .totalBox {margin-left: 0;}
.totalTWrap {position: relative;}
.totalText {width: 555px;font-size: 14px;position: absolute;background: #fff;right: 0;top: 37px;z-index: 10;border: 5px solid #353535;padding: 10px;}
.totalT {width: 26px;height: 26px;background: #353535;color: #fff;border-radius: 30px;display: flex;align-items: center;justify-content: center;margin-left: 10px;cursor: pointer;}
.totalBox {margin: 10px 17px; border: 2px solid #97bab8; }
.totalBox td:last-child, .mainTable td:last-child {border-right: 0;}
.yw .totalBox, .yw .mainTable {border-color: #f1c578;}
.yw .money li.last {background: #fff6e5;}
.money li {text-align: left;padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;}
.money li.head {justify-content:center;font-weight: bold;}
.money li.last {background: #e4efee;font-weight: bold;}
.money li i {font-weight: bold;color: #df6951;}

.moreT {cursor: pointer;}
.moreT img {/*margin: 0 6px 5px 0;*/}
.moreTable {width: 100%;}
.moreTable .moreTable {border: 0;}
/*.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}*/
.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.mainTable td {position: relative;}
.mainTable .name {text-align: left;}
.mainTable .name span {margin-left: 35px;display: block;}
.moreTable .name span {display: flex;}

.moreTable .moreTable .name::before {
  left: 35px;
}
.moreTable .moreTable .moreTable .name::before {
  left: 45px;
}
.moreTable .moreTable .moreTable .moreTable .name::before {
  left: 55px;
}
.moreTable .moreTable .moreTable .moreTable .moreTable .name::before {
  left: 65px;
}
.moreTable .moreTable td a.moreT::before {
  left: 45px;
}
.moreTable .moreTable .moreTable td a.moreT::before {
  left: 55px;
}
.moreTable .moreTable .moreTable .moreTable td a.moreT::before {
  left: 65px;
}
.moreTable .moreTable .moreTable .moreTable .moreTable td a.moreT::before {
  left: 75px;
}
.mainTable td a.moreT img {width: 15px;}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}

.tableName {padding: 20px 0 0; margin: 0 17px; font-size: 20px; font-weight: 600; color: #202020;}
.tableName2 {margin-bottom: 10px; font-size: 20px; font-weight: 600; color: #202020;}
.mainTable .mainTable {border: 0;}
.mainTable .mainTable td {background: #f5f5f5;}
.ratebox {padding: 0 10px;max-height: 120px;overflow-y: auto;}
.ratebox::-webkit-scrollbar {
    width: 5px;
  }
  .ratebox::-webkit-scrollbar-thumb {
    background-color: #82a6a4;
    border-radius: 5px;
    background-clip: padding-box;
    border: 1px solid transparent;
    box-sizing: border-box;
  }
  .ratebox::-webkit-scrollbar-track {
    background-color: #e1e0e0;
    border-radius: 5px;
  }
.rateWrap {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.rateWrap > li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.rateWrap > li > span {
  width: 55px;
  display: inline-block;
  text-align: left;
}
</style>
