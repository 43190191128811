<template>
  <tr>
    <td class="name">
      <div :class="{'box2': table === 'more'}" >
         <em class="box">{{data.siteType === 'master' ? 'MM' : 'Son'}}</em>
         <span class="block-box">
            <em class="block"><a @click="detailOpen('partner', data.siteId)" class="pid">{{data.siteId}}</a></em>
            <em class="block">{{ data.siteName }} ({{numberWithCommas(data.cnt)}})</em>
         </span>
      </div>
      <a class="moreBtns" :class="{ 'hiddenTable' : more}" @click="getMore" v-if="data.cnt">▶</a>
    </td>
    <td class="v-align-top">
     <ul class="money">
       <li><em>{{ $t('table.head.holdingIs') }}</em>{{numberWithCommas(data.insuredBalance)}}</li>
       <li><em>{{ $t('table.body.pointBiAmt') }}</em>{{numberWithCommas(data.insuredPointBalance)}}</li>       
     </ul>
    </td>
    <template v-for="item in data.reports" :key="item.category1">
      <td class="v-align-top">
        <ul class="money">
          <li><em>{{ $t('common.bet') }}</em><span>{{numberWithCommas(item.biBetAmt)}}<i>({{numberWithCommas(item.insuredBetAmt)}})</i></span></li>
          <li><em>{{ $t('common.win') }}</em><span>{{numberWithCommas(item.biWinAmt)}}<i>({{numberWithCommas(item.insuredWinAmt)}})</i></span></li>
          <li><em>{{ $t('common.rolling') }}[{{ $t('table.head.BIAmt') }}] <i>({{item.biSubPointRate}}%)</i></em><span>{{numberWithCommas(item.insuredPointAmt)}}</span></li>
          <li><em>{{ $t('table.head.wl') }}</em><span>{{numberWithCommas(item.biWinLose)}}<i>({{numberWithCommas(item.insuredAmt*-1)}})</i></span></li>
          <li><em>{{ $t('table.head.insureM') }} <i>({{item.insureRate}}%)</i></em><span>{{numberWithCommas(item.insuredAmt)}}</span></li>
          <li><em>{{ $t('table.head.insureCpnyshare') }}</em><span>{{numberWithCommas(item.creditAmt*-1)}}</span></li>          
        </ul>
      </td>
    </template>

    <td class="v-align-top">
      <ul class="money">
        <li><em>{{ $t('header.totalBets') }}</em><span>{{numberWithCommas(data.reportSum.biBetAmtSum)}}<i>({{numberWithCommas(data.reportSum.insuredBetAmtSum)}})</i></span></li>
        <li><em>{{ $t('header.totalWins') }}</em><span>{{numberWithCommas(data.reportSum.biWinAmtSum)}}<i>({{numberWithCommas(data.reportSum.insuredWinAmtSum)}})</i></span></li>
        <li><em>{{ $t('table.head.totalRoll') }}</em><span>{{numberWithCommas(data.reportSum.insuredPointAmtSum)}}</span></li>
        <li><em>{{ $t('table.head.wl') }}</em><span>{{numberWithCommas(data.reportSum.biWinLoseSum)}}<i>({{numberWithCommas(data.reportSum.insuredAmtSum*-1)}})</i></span></li>
        <li class="last"><em>{{ $t('table.head.insureM') }}</em><span>{{numberWithCommas(data.reportSum.insuredAmtSum)}}</span></li>
        <li><em>{{ $t('table.head.insureCpnyshare') }}</em><span>{{numberWithCommas(parseInt(data.reportSum.creditAmtSum*-1) + parseInt(data.reportSum.insuredPointAmtSum))}}</span></li>
      </ul>
    </td>
  </tr>
  <template v-if="more && inSearchList.length > 0">
    <tr>
      <td :colspan="data.reports.length + 3">
        <component :is="component" v-if="inSearchList.length > 0" :key="data.siteId"
                   :table="'more'"
                   :list="inSearchList"
                   :reqData="reqData"
                   :myReport=myReport />
      </td>
    </tr>

  </template>
</template>

<script>
import { mapState } from 'vuex'
import { insuredReport } from '@/api/insured'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'insureTr',
  props: [
    'data',
    'reqData',
    'myReport',
    'table'
  ],
  computed: {
    ...mapState([
    ]),
    component () {
      return defineAsyncComponent(() => import('@/components/ui/insure/insureTable.vue'))
    }
  },
  mounted () {
  },
  data () {
    return {
      more: false,
      inSearchList: []
    }
  },
  watch: {
    data () {
      this.inSearchList = []
      this.more = false
    }
  },
  methods: {
    async getMore () {
      this.emitter.emit('Loading', true)
      console.log(this.inSearchList)
      if (this.inSearchList.length === 0) {
        const reqData = { ...this.reqData }
        const memId = this.data.siteId
        reqData.searchType = 'INNER'
        reqData.siteId = memId
        reqData.page = 1
        console.log(reqData)
        const res = await insuredReport(reqData)
        console.log(res)
        if (res.resultCode === '0') {
          this.inSearchList = res.data.reportList
          this.inSearchList.forEach(item => {
            if (item.reports.length > 0) {
              item.reports = item.reports.filter(subItem => {
                return subItem.category1 === 'casino'
              })
            }
          })

          setTimeout(function () {
            this.loader = true
          }.bind(this), 500)
        }
      } else {
        this.inSearchList = []
      }
      this.more = !this.more
      this.emitter.emit('Loading', false)
    }
  }
}
</script>

<style scoped>
.block {display: block;}
.block a {cursor: pointer;}
.lRoll span {display: block;}
.lRoll span:first-child {margin-bottom: 6px;}
.lRoll em {font-size: 12px;}
.left {text-align: left;padding-left: 5px;}
.v-align-top {vertical-align: top;}
.mainTable tr {transition: all .4s ease-in-out;}

.money li {text-align: left;padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;}
.money li.head {justify-content:center;font-weight: bold;}
.money li.last {background: #e4efee;font-weight: bold;}
.money li i {font-weight: bold;color: #df6951;}

.name > div {display: flex;align-items: center;gap: 5px;}
.block-box {display: flex;flex-direction: column;gap: 3px;}

.mainTable .name .box {color: #fff;padding: 2px 4px;border-radius: 3px;display: inline-block;margin-bottom: 3px;}
.mainTable .name {color: #b51d44;}
.mainTable .name .box {background: #b51d44;}
.mainTable .moreTable .name {color: #a300ab;}
.mainTable .moreTable .name .box {background: #a300ab;}
.mainTable .moreTable .moreTable .name {color: #5b39bc;}
.mainTable .moreTable .moreTable .name .box {background: #5b39bc;}
.mainTable .moreTable .moreTable .moreTable .name {color: #754b23;}
.mainTable .moreTable .moreTable .moreTable .name .box {background: #754b23;}
.mainTable .moreTable .moreTable .moreTable .moreTable .name {color: #047972;}
.mainTable .moreTable .moreTable .moreTable .moreTable .name .box {background: #047972;}
.mainTable .moreTable .moreTable .moreTable .moreTable .moreTable .name {color: #b53f3f;}
.mainTable .moreTable .moreTable .moreTable .moreTable .moreTable .name .box {background: #b53f3f;}

.box2 {padding-left: 30px;box-sizing: border-box;}
.box2 .box {background: #a300ab !important;}
.box3 .box {background: #5b39bc !important;}

.ratebox {padding: 0 10px;max-height: 120px;overflow-y: auto;}
.ratebox::-webkit-scrollbar {
    width: 5px;
  }
  .ratebox::-webkit-scrollbar-thumb {
    background-color: #82a6a4;
    border-radius: 5px;
    background-clip: padding-box;
    border: 1px solid transparent;
    box-sizing: border-box;
  }
  .ratebox::-webkit-scrollbar-track {
    background-color: #e1e0e0;
    border-radius: 5px;
  }
.rateWrap {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.rateWrap > li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.rateWrap > li > span {
  width: 55px;
  display: inline-block;
  text-align: left;
}
.sub{
  color:#0069cc;
}
a.moreBtns {position: absolute;left: 5px;top: 50%;cursor: pointer;font-size: 22px;display: flex;align-items: center;justify-content: center;transition: all .2s ease-out;color: #0a7b0e;margin-top: -11px;}
a.moreBtns.hiddenTable {transform: rotate(90deg);transition: all .2s ease-in;color: #db8806;}
</style>
